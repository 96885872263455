<script>
import Layout from "@/router/layouts/auth";
import { mapState } from "vuex";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {
    },
    es: {
    }
  },
  components: {
    Layout,
  },
  computed: {
    ...mapState({
      account: state => state.account
    })
  },
  data() {
    return {
    };
  },
  methods: {
    getAuth() {
      api
        .post("integrations/erp/bling", {
          'code': this.$route.query.code
        })
        .then((response) => {
          if (response.data.status == "success") {
            this.$toast.success("O bling foi autorizado com sucesso.");
            this.$router.push('/integrations');
          } else {
            this.$toast.error("Não foi possível autorizar o bling, tente novamente.");
            this.$router.push('/integrations');
          }
        })
        .catch((error) => {
          if (error) {
            this.$toast.error("Não foi possível autorizar o bling, tente novamente.");
            this.$router.push('/integrations');
          }
        })
    },
  },
  mounted() {
    this.getAuth();
  }
};
</script>

<template>
  <Layout>
    <div class="w-100 vh-100 d-flex justify-content-center align-items-center">
      <b-spinner large class="align-middle" variant="default" role="status"></b-spinner>
    </div>
  </Layout>
</template>